<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-if="checkPermission([consts.CUSTOMER_LIST_VIEW])"
              :to="{
                name: 'CustomerList',
              }"
              >會員管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>會員資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <b-card class="mr-3 w-100 p-3">
        <b-overlay :show="showLoading" rounded="sm">
          <div class="row align-items-start">
            <!-- Top -->
             <div class="col-12 col-xl-6">
              <CustomerCard
                :customer="customer"
                :show-unbind-button="customers.length > 0"
                @trigger-fetch-customer="getCustomer"
                @trigger-init-data="loadInitialData"
              />
            </div>


            <div class="flex-fill col-12 col-xl-6">
              <div class="d-flex flex-row align-items-center" v-for="(i,idx) in customers" :key="i.id">
                <template v-if="showCustomers || idx==0">
                  <i class="mdi mdi-link-variant mdi-rotate-45 mr-2 h3 text-secondary"></i>
                  <CustomerCard
                    :customer="i"
                    :show-unbind-button="customers.length > 0"
                    :show-underline="true"
                    @trigger-fetch-customer="getCustomer"
                    @trigger-init-data="loadInitialData"
                  />
                </template>
              </div>
              <b-button v-if="customers.length>1" class="w-100 d-flex justify-content-between" variant="secondary" @click="showCustomers=!showCustomers">
                {{ showCustomers?'隱藏內容':'展開更多' }}
                <i class="mdi" :class="`mdi-chevron-double-${showCustomers?'up':'down'}`"></i></b-button>
            </div>
          </div>

          <!-- Bottom -->
          <div class="mt-5">
            <b-tabs v-model="activeTabIndex" @input="handleTab">
              <b-tab
                v-for="(tab, index) in tabs"
                v-bind:key="index"
                :title="tab.title"
              ></b-tab>
            </b-tabs>

            <div v-if="activeTabHash === '#tag'">
              <div class="p-3">
                <div v-if="!isEditTags">
                  <b-badge
                    pill
                    variant="dark"
                    v-for="tag in orderedTags"
                    :key="tag.id"
                    class="mr-1"
                    >{{ tag }}</b-badge
                  >
                </div>
                <b-form-tags
                  v-else
                  input-id="tags"
                  v-model="tags"
                  placeholder="輸入標籤"
                  size="lg"
                  tag-variant="dark"
                  remove-on-delete
                  separator=" ,;"
                  addButtonText="新增"
                ></b-form-tags>
                <div
                  v-if="checkPermission([consts.CUSTOMER_LIST_EDIT])"
                  class="mt-3 text-center"
                >
                  <b-button
                    v-if="!isEditTags"
                    class="flex-shrink-0"
                    variant="inverse-warning"
                    @click="isEditTags = true"
                  >
                    編輯標籤
                  </b-button>

                  <template v-else>
                    <b-button
                      class="mr-2"
                      variant="inverse-info"
                      @click="handleCancelTags"
                    >
                      取消
                    </b-button>
                    <b-button variant="inverse-primary" @click="handleSaveTags">
                      儲存
                    </b-button>
                  </template>
                </div>
              </div>
            </div>

            <div v-if="activeTabHash === '#basic'">
              <div class="p-3">
                <div>姓名：{{ customer.name }}</div>
                <div>手機：{{ customer.mobile_phone }}</div>
                <div>Email：{{ customer.email }}</div>
                <div>生日：{{ customer.birthday }}</div>
                <div>性別：{{ customer.sexual }}</div>
                <div>好友：{{ customer.is_friend ? "是" : "否" }}</div>
                <div>系統編號：{{ customer.outer_code }}</div>
                <div>建立時間：{{ customer.created_at }}</div>
                <div>加入時間：{{ customer.join_datetime }}</div>
                <div v-if="customer.leave_datetime">
                  離開時間：{{ customer.leave_datetime }}
                </div>
                <div>更新時間：{{ customer.updated_at }}</div>
                <div>
                  手機是否驗證：{{ customer.phone_verified ? "是" : "否" }}
                </div>
                <div>手機驗證時間：{{ customer.phone_verified_at }}</div>
                <div>註冊時間：{{ customer.registered_at }}</div>
                <div>條款接受時間：{{ customer.accept_term_at }}</div>
              </div>
            </div>

            <div v-if="activeTabHash === '#detail'">
              <div class="p-3">
                <div v-for="(field, index) in fields" :key="'field' + index">
                  {{ field.title }}：{{ customer.profiles[field.matched_key] }}
                </div>
              </div>
            </div>

            <div v-if="activeTabHash === '#staff-bind-list'">
              <customer-staff-list />
            </div>

            <div v-if="activeTabHash === '#list-by-user-id'">
              <CustomerListByUserID />
            </div>

            <div v-if="activeTabHash === '#list-point-logs'" class="p-3">
              <PointLog
                :showFilter="false"
                :excludeFields="['customer_name', 'actions']"
                :upperMerchantId="customer.merchant.id"
                :upperCustomerId="$route.params.customerID"
              />
            </div>
            <div v-if="activeTabHash === '#list-redeem-logs'" class="p-3">
              <RedeemLog
                :showFilter="false"
                :includeFields="[
                  'created_at',
                  'gift_name',
                  'gift_data',
                  'batch_total_points',
                ]"
                :upperMerchantId="customer.merchant.id"
                :upperCustomerId="$route.params.customerID"
              />
            </div>

            <div
              v-if="activeTabHash === '#list-redeem-code-belonged'"
              class="p-3"
            >
              <BelongedRedeemCode
                :showFilter="false"
                :excludeFields="['customer']"
                :upperCustomer="customer"
              />
            </div>

            <div v-if="activeTabHash === '#list-orders'" class="p-3">
              <Order
                  :showFilter="false"
                  :excludeFields="['customer_name', 'action']"
                  :upperCustomerId="$route.params.customerID"
                  :upperMerchantId="customer.merchant.id"
              />
            </div>
          </div>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import customerApi from "@/apis/customer";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import _ from "lodash";
import { isValidJsonArray, transformJsonToString } from "@/utils/jsonHelper";
import CustomerStaffList from "./CustomerStaffList.vue";
import CustomerListByUserID from "./CustomerListByUserID.vue";
import CustomerCard from "@/components/Dashboard/Customer/CustomerCard.vue";
import PointLog from "@/components/Dashboard/PointLog/PointLog.vue";
import RedeemLog from "@/components/Dashboard/PointLog/RedeemLog.vue";
import BelongedRedeemCode from "@/components/Dashboard/RedeemCodeBundle/BelongedRedeemCode.vue";
import Order from "@/components/Dashboard/Order/Order.vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import merchantApi from "@/apis/merchant";
import moment from 'moment'

export default {
  components: {
    CustomerStaffList,
    CustomerListByUserID,
    CustomerCard,
    PointLog,
    RedeemLog,
    BelongedRedeemCode,
    Order,
  },
  data() {
    return {
      customer: {
        id: this.$route.params.customerID,
        profiles: {},
        merchant: {
          type_name: null,
        },
      },
      customers: [],
      showCustomers:false,
      text: "{customers.data}",
      showLoading: false,
      profileMappings: [],
      consts,
      permissionChecker: new PermissionChecker(),
      fields: [],
      activeTabIndex: 0,
      isEditTags: false,
      tags: [],
      merchants: [],
      activeTabHash: "#tag",
      tabs: [],
      initialized: false,
      hasDetailSetting: false,
    };
  },
  computed: {
    ...mapGetters("general", ["organization"]),
    isEdit() {
      return this.$route.name === "CustomerDetailEdit";
    },
    moment () {
      return moment
    },
    orderedTags() {
      let groupedTags = Map.groupBy(this.tags, (tag) => tag);

      return [...groupedTags]
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map((tag) => {
          let tagName = tag[0];
          if (tag[1].length > 1) {
            tagName += `(${tag[1].length})`;
          }
          return tagName;
        });
    },
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    isValidJsonArray,
    transformJsonToString,
    async loadInitialData() {
      await Promise.all([
        this.getMerchants(),
        this.getCustomer(),
        this.getSameCustomerList(),
      ]);
      await this.calculateTabs();
      this.setActiveTab();
      this.initialized = true;
    },
    calculateTabs() {
      const hasDetailPermission = !!this.fields;
      const hasStaffBindListPermission = this.checkPermission([
        consts.CUSTOMER_STAFF_VIEW,
      ]);
      //const isMutilMerchants = this.merchants.length > 1;
      const hasPointLogPermission = this.checkPermission([
        consts.POINT_LOGS_LIST_VIEW,
      ]);
      const hasRedeemLogPermission = this.checkPermission([
        consts.REDEEM_LIST_VIEW,
      ]);
      const hasRedeemCodeUsedListPermission = this.checkPermission([
        consts.REDEEM_CODE_USED_LIST_VIEW,
      ]);
      const hasOrderListPermission = this.checkPermission([
        consts.ORDER_LIST_VIEW,
      ]);

      let tabs = [
        { title: "標籤", href: "#tag" },
        { title: "基本資料", href: "#basic" },
      ];

      if (hasDetailPermission && this.hasDetailSetting) {
        tabs.push({ title: "詳細資料", href: "#detail" });
      }

      if (hasStaffBindListPermission) {
        tabs.push({ title: "所屬業務", href: "#staff-bind-list" });
      }

      // if (isMutilMerchants) {
      //   tabs.push({ title: "來源通路", href: "#list-by-user-id" });
      // }

      if (hasPointLogPermission) {
        tabs.push({ title: "點數歷程", href: "#list-point-logs" });
      }

      if (hasRedeemLogPermission) {
        tabs.push({ title: "贈品紀錄", href: "#list-redeem-logs" });
      }

      if (hasRedeemCodeUsedListPermission) {
        tabs.push({ title: "優惠券", href: "#list-redeem-code-belonged" });
      }

      if (hasOrderListPermission) {
        tabs.push({ title: "訂單紀錄", href: "#list-orders" });
      }

      this.tabs = tabs;
    },
    setActiveTab() {
      this.activeTabHash = this.$route.hash || "#tag";
      this.activeTabIndex = this.tabs.findIndex(
        (tab) => tab.href === this.activeTabHash
      );
    },
    async getMerchants() {
      const { data } = await merchantApi.list();
      this.merchants = data.data;
    },
    async getCustomer() {
      try {
        this.showLoading = true;
        const { data } = await customerApi.getCustomer(
          this.$route.params.customerID
        );
        this.customer = {
          ...data,
          birthday:
            data.birthday && format(new Date(data.birthday), "yyyy-MM-dd"),
          join_datetime:
            data.join_datetime &&
            format(new Date(data.join_datetime), "yyyy-MM-dd HH:mm"),
          leave_datetime:
            data.leave_datetime &&
            format(new Date(data.leave_datetime), "yyyy-MM-dd HH:mm"),
          created_at: format(new Date(data.created_at), "yyyy-MM-dd HH:mm"),
          updated_at: format(new Date(data.updated_at), "yyyy-MM-dd HH:mm"),
          phone_verified_at:
            data.phone_verified_at &&
            format(new Date(data.phone_verified_at), "yyyy-MM-dd HH:mm"),
          registered_at:
            data.registered_at &&
            format(new Date(data.registered_at), "yyyy-MM-dd HH:mm"),
          accept_term_at:
            data.accept_term_at &&
            format(new Date(data.accept_term_at), "yyyy-MM-dd HH:mm"),
        };
        // 處理是checkbox類別的 內容要還原回json
        Object.keys(this.customer.profiles).forEach((key) => {
          const value = this.customer.profiles[key];
          // 在這裡處理屬性和值
          if (this.isValidJsonArray(value)) {
            this.customer.profiles[key] = this.transformJsonToString(
              JSON.parse(value)
            );
          }
        });
        this.profileMappings = data.meta.profile_mappings;
        this.text = JSON.stringify(data.data);

        let fields = data.meta.module_config?.member_fields ?? [];
        this.hasDetailSetting = fields.length;
        this.fields = _.sortBy(fields, "order");
        Object.keys(this.fields).forEach((key) => {
          this.fields[key]["matched_key"] = this.fields[key][
            "matched_key"
          ].replace(/extra./g, "");
        });

        this.tags = this.customer.tags.map((tag) => {
          return tag.name;
        }).sort((a, b) => a.localeCompare(b));
        this.showLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getSameCustomerList() {
      try {
        const { data } = await customerApi.getSameUserCustomers(
          this.$route.params.customerID
        );
        this.customers = data.data.filter(
          (x) => x.id != this.$route.params.customerID
        );
        this.customers.forEach((customer) => {
          if (!customer.merchant_type_name) {
            customer.merchant_type_name = {};
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    handleCancel() {
      this.$router.push({ name: "CustomerList" });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleTab(tabIndex) {
      if (!this.initialized) {
        return;
      }
      const newHash = this.tabs[tabIndex]?.href;
      const currentPath = this.$route.path;

      this.$router
        .replace({
          path: currentPath,
          hash: newHash,
          query: {},
        })
        .then(() => {
          this.activeTabHash = newHash;
        })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
    },
    handleCancelTags() {
      this.tags = this.customer.tags.map((tag) => {
        return tag.name;
      }).sort((a, b) => a.localeCompare(b));
      this.isEditTags = false;
    },
    async handleSaveTags() {
      let payload = {
        tags: this.tags.map((t) => {
          return { name: t };
        }),
      };
      await customerApi.setCustomerTags(this.customer.id, payload);
      this.fetchCustomerTags(this.customer.id);
      this.isEditTags = false;
    },
    async fetchCustomerTags(customerId) {
      const { data } = await customerApi.getCustomerTags(customerId);
      this.tags = data.data.map((t) => {
        return t.name;
      }).sort((a, b) => a.localeCompare(b));
      this.customer.tags = data.data.map((t) => {
        return { name: t.name, id: t.id  };
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
.wrapper{
  text-wrap:wrap;
}
</style>
